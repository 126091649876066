import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import { NavBar } from "./components/nav-bar";
import { NavBarMobile } from "./components/nav-bar-mobile";

import { Dashboard } from "./pages/dashboard";
import { ProtectedRoute } from "./components/protected-route";
import { ProtectedRouteFree } from "./components/protected-route-free";

import { Login } from "./pages/login";
import ScrollToTop from "./hooks/ScrollToTop";
import { NotFound } from "./pages/not-found";
import { Profile } from "./pages/profile";
import { ProfileEdit } from "./pages/profile-edit";
import { Lifeline } from "./pages/lifeline/lifeline";
import { Moment } from "./pages/lifeline/moment";
import { MomentEdit } from "./pages/lifeline/edit";
import { LifelineAdd } from "./pages/lifeline/add";

import { RosesAndThorns } from "./pages/RosesAndThorns";

import { HighlightReel } from "./pages/HighlightReel";
import { HighlightReelAdd } from "./pages/HighlightReel/add";
import { HighlightShare } from "./pages/HighlightReel/share";

import { StoryboardAdd } from "./pages/storyboard/add";

import { Admin } from "./pages/Admin/index";
import { AdminCompanies } from "./pages/Admin/companies";
import { AdminCoupons } from "./pages/Admin/coupons";
import { AdminCouponsCreate } from "./pages/Admin/couponsCreate";

import { AdminLearningActivities } from "./pages/Admin/learningActivities";
import { LearningActivitiesCreate } from "./pages/Admin/learningActivitiesCreate";
import { LearningActivitiesEdit } from "./pages/Admin/learningActivitiesEdit";

import { AdminMemberships } from "./pages/Admin/memberships";

import { GroupAdd } from "./pages/Admin/Groups/add";
import { GroupEdit } from "./pages/Admin/Groups/edit";

import { Crews } from "./pages/Admin/Crews/index";

import { EventsAdmin } from "./pages/Admin/Events/index";

import { Programs } from "./pages/Admin/Programs/index";
import { ProgramEdit } from "./pages/Admin/Programs/edit";
import { ProgramsSingle } from "./pages/Admin/Programs/single";

import { CrewsSingle } from "./pages/Admin/Crews/single";
import { CrewsEdit } from "./pages/Admin/Crews/edit";
import { ProgramTimes } from "./pages/Admin/Crews/times";
import { ProgramStats } from "./pages/Admin/Crews/stats";
import { PlatformStats } from "./pages/Admin/Crews/platformStats";

import { LessonsSingle } from "./pages/Admin/Lessons/single";
import { LessonsEdit } from "./pages/Admin/Lessons/edit";

import { LessonTemplatesSingle } from "./pages/Admin/LessonTemplates/single";
import { LessonTemplatesEdit } from "./pages/Admin/LessonTemplates/edit";
import { Tool } from "./pages/Tool/single";

import { ActivitiesSingle } from "./pages/Admin/Activities/single";
import { ActivitiesEdit } from "./pages/Admin/Activities/edit";

import { StoryboardDashboard } from "./pages/storyboard/dashboard";
import { Waitlist } from "./pages/waitlist";
import { FinishedOnboarding } from "./pages/finishedonboarding";
import { VideoReview } from "./pages/storyboard/videoreview";
import { View } from "./pages/storyboard/view";
import { Resend } from "./pages/storyboard/resend";

import { Strengths } from "./pages/Strengths/index";
import { StrengthsFive } from "./pages/Strengths/five";

import { StrengthInvites } from "./pages/Strengths/invites";
import { StrengthSort } from "./pages/Strengths/sort";
import { StrengthOverview } from "./pages/Strengths/overview";
import { StrengthInvite } from "./pages/Strengths/invite";

import { Learn } from "./pages/Learn/index";
import { LearnActivities } from "./pages/Learn/activities";
import { LearnPaths } from "./pages/Learn/paths";
import { LearnPath } from "./pages/Learn/path";
import { Program } from "./pages/Programs/index";

import { Story } from "./pages/storyboard/story";
import mixpanel from "mixpanel-browser";
import LogRocket from "logrocket";
import { Helmet } from "react-helmet";
import { useEnv } from "./context/env.context";
import axios from "axios";
import { Memberships } from "./pages/Membership";
import { MembershipsCompany } from "./pages/Membership/company";

import { MembershipSuccess } from "./pages/Membership/success";
import { PartialMembership } from "./pages/Membership/partial";
import { PartialMembershipSuccess } from "./pages/Membership/partialSuccess";
import { HighlightShareSingle } from "./pages/HighlightReel/highlightShare";
import { Onboarding } from "./pages/onboarding";
import { Signup } from "./pages/signup";

import { Values } from "./pages/Values/index";
import { ValuesCreation } from "./pages/Values/valuesCreation";
import { ValuesDashboard } from "./pages/Values/dashboard";
import { ValueInvite } from "./pages/Values/invite";
import { FeedbackInvite } from "./pages/Values/feedback";
import { CompanySignup } from "./pages/Company/CompanySignup";
import { CompanyOnboarding } from "./pages/Company/onboarding";
import { CompanyIntro } from "./pages/Company/intro";
import { DashboardRouter } from "./pages/Programs/DashboardRouter";
import { Visions } from "./pages/Visions/index";
import { EnterPages } from "./pages/Visions/EnterPages";
import { VisionsDashboard } from "./pages/Visions/dashboard";
import { CoverPage } from "./pages/Visions/CoverPage";
import { PublicVision } from "./pages/Visions/public";
import { CoachingDashboard } from "./pages/Coaching/dashboard";
import { CoachingProgram } from "./pages/Coaching/Program";
import { CareerCollaborators } from "./pages/CareerCollaborators";
import { CareerCollaboratorsEnter } from "./pages/CareerCollaborators/Enter";
import { CareerCollaboratorsReview } from "./pages/CareerCollaborators/Review";
import { CareerCollaboratorsDashboard } from "./pages/CareerCollaborators/dashboard";
import { CoverPageEdit } from "./pages/Visions/CoverPageEdit";
import { TyingItAllTogether } from "./pages/Tying";
import { Topics } from "./pages/Tying/Topics";
import { TopicsDashboard } from "./pages/Tying/dashboard";
import { PrivacyPolicy } from "./pages/PrivacyPolicy";
import { InterestForm } from "./pages/InterestForm";
import { OpenInterestForm } from "./pages/OpenInterestForm";
import { AdminInterests } from "./pages/Admin/Interests";

import { InterestFormSignup } from "./pages/InterestFormSignup";
import { RoleModels } from "./pages/RoleModels";
import { RoleModelsDashboard } from "./pages/RoleModels/dashboard";
import { RoleModelValuesCreation } from "./pages/RoleModels/roleModelValues";
import { Mindmap } from "./pages/Mindmap";
import { CreateDiagram } from "./pages/Mindmap/components/CreateDiagram";
import { CollabDiagram } from "./pages/Mindmap/components/CollabDiagram";
import { MindmapDashboard } from "./pages/Mindmap/dashboard";

LogRocket.init("7rx1qq/storyboard");
mixpanel.init("da41e3e0a614db0f3873bdccf0c75733", { debug: true });

export const App = () => {
  const { isAuthenticated, logout } = useAuth0();
  const [me, setMe] = useState(null);
  const [member, setMember] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const { apiServerUrl } = useEnv();
  const [loadingDone, setLoadingDone] = useState(false);
  const [minimized, setMinimized] = useState(false);
  const history = useHistory();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getMe = async () => {
    try {
      const token = await getAccessTokenSilently();

      const config = {
        url: `${apiServerUrl}/api/users/me`,
        method: "GET",
        headers: {
          "content-type": "application/json",
        },
      };
      config.headers = {
        Authorization: `Bearer ${token}`,
      };
      const response = await axios(config);
      if (response.data.memberships.length > 0) {
        setMember(response.data.memberships[0]);
      }
      setLoadingDone(true);
      setMe(response.data);

      const programUser = response.data.crew.length > 0;

      const params = new URLSearchParams(window.location.search);
      const skipOnboarding = params.get("skipOnboarding");
      // They are a member but haven't onboarded
      if (
        !response.data.finishedOnboarding &&
        response.data.memberships.length > 0 &&
        !programUser &&
        response.data.roles[0].name !== "Coach" &&
        !skipOnboarding
      ) {
        return history.push("/onboarding");
      }
    } catch (error) {
      setLoadingDone(true);
    }
  };

  useEffect(() => {
    if (!me) {
      getMe();
    }
  }, []);

  if (!loadingDone) {
    return (
      <div className="page-layout">
        <p></p>
      </div>
    );
  }

  if (!isAuthenticated && window.location.pathname === "/") {
    return (
      <div className="login">
        <Switch>
          <Route path="/" exact component={Login} />
        </Switch>
      </div>
    );
  }

  return (
    <div className={`page-layout`}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Welcome To Crew</title>
        <meta
          name="description"
          content="Modern careers are messy, let's navigate them together. At Crew, we’re building some really cool tools and experiences designed to help you gain clarity on your next step."
        />
        <meta property="og:title" content={`Welcome To Crew`} />
        <meta
          property="og:description"
          content={`Modern careers are messy, let's navigate them together. At Crew, we’re building some really cool tools and experiences designed to help you gain clarity on your next step.`}
        />
        <meta
          property="og:image"
          itemProp="image"
          content={`https://crewassets.s3.us-east-2.amazonaws.com/crew_logo_mark.jpg`}
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta property="twitter:title" content={`Welcome To Crew`} />
        <meta
          property="twitter:description"
          content={`Modern careers are messy, let's navigate them together. At Crew, we’re building some really cool tools and experiences designed to help you gain clarity on your next step.`}
        />
        <meta
          property="twitter:image"
          itemProp="image"
          content={`https://crewassets.s3.us-east-2.amazonaws.com/crew_logo_mark.jpg`}
        />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="page-layout__content">
        <NavBar
          setMinimized={() => {
            setMinimized(!minimized);
          }}
          minimized={minimized}
          member={member}
          me={me}
          logout={logout}
        />
        <NavBarMobile me={me} member={true} logout={logout} />

        <ScrollToTop />
        <div className={`content_container ${minimized ? "minimized" : ""}`}>
          <Switch>
            <ProtectedRoute
              member={member}
              path="/"
              exact
              component={Dashboard}
            />

            {/* Storyboard */}
            <ProtectedRoute
              path="/story/resend/:id"
              component={Resend}
              member={member}
            />

            <Route
              path="/membership/success/:id"
              component={PartialMembershipSuccess}
            />
            <Route path="/membership/:id" component={PartialMembership} />
            <Route path="/membership/success" component={MembershipSuccess} />
            <Route path="/membership/company" component={MembershipsCompany} />
            <Route path="/company/signup" component={CompanySignup} />

            <Route path="/signup/interest/:id" component={InterestFormSignup} />
            <Route path="/signup/interest" component={InterestFormSignup} />
            <Route path="/interest/onboarding/:id" component={CompanyIntro} />

            <Route
              path="/company/onboarding"
              member={member}
              component={CompanyOnboarding}
            />
            <ProtectedRoute
              path="/company/intro"
              member={member}
              component={CompanyIntro}
            />

            <Route path="/membership" component={Memberships} />
            <Route path="/story/:id" component={Story} />
            <Route path="/view/:id" component={View} />

            <ProtectedRouteFree
              path="/storyboard/video-review"
              component={VideoReview}
              member={member}
            />
            <ProtectedRouteFree
              member={member}
              path="/onboarding"
              component={() => {
                return <Onboarding me={me} />;
              }}
            />
            <Route
              path="/storyboard/dashboard"
              component={StoryboardDashboard}
            />
            <ProtectedRouteFree
              member={member}
              path="/storyboard/add"
              component={StoryboardAdd}
            />

            <ProtectedRouteFree
              member={member}
              path="/storyboard"
              component={StoryboardDashboard}
            />

            {/* Lifeline */}
            <ProtectedRoute
              member={member}
              path="/lifeline/add"
              component={LifelineAdd}
            />
            <ProtectedRoute
              path="/lifeline/moment/:id/edit"
              component={MomentEdit}
              member={member}
            />

            <ProtectedRoute
              member={member}
              path="/lifeline/moment/:id"
              component={Moment}
            />
            <ProtectedRoute
              member={member}
              path="/lifeline"
              component={Lifeline}
            />

            {/* Roses and Thorns */}
            <ProtectedRoute
              path="/roses-and-thorns"
              component={RosesAndThorns}
              member={member}
            />

            {/* Highlight Reel */}
            <ProtectedRoute
              path="/highlights/add"
              component={HighlightReelAdd}
              member={member}
              toolId={3}
            />

            <Route
              path="/highlights/highlight/:id"
              component={HighlightShareSingle}
            />

            <Route path="/highlights/share/:id" component={HighlightShare} />

            <Route path="/highlights/:id" component={HighlightReel} />

            {/* Highlight Reel */}
            <ProtectedRoute
              member={member}
              toolId={3}
              path="/highlights"
              component={HighlightReel}
            />

            <ProtectedRoute
              member={member}
              path="/waitlist"
              component={Waitlist}
            />
            <ProtectedRouteFree
              member={member}
              path="/profile/edit"
              component={ProfileEdit}
            />
            <ProtectedRoute
              member={member}
              path="/profile"
              component={Profile}
            />
            <ProtectedRoute
              path="/finished-onboarding"
              component={FinishedOnboarding}
              member={member}
            />

            <ProtectedRoute
              path="/path/:id/dashboard"
              component={() => {
                return <DashboardRouter member={member} me={me} />;
              }}
              me={me}
              member={member}
            />
            <ProtectedRoute
              member={member}
              me={me}
              path="/path/:id"
              component={() => {
                return <DashboardRouter member={member} me={me} />;
              }}
            />

            <ProtectedRoute member={member} path="/tool/:id" component={Tool} />

            <ProtectedRoute
              member={member}
              me={me}
              path="/dashboard"
              component={() => {
                return <DashboardRouter member={member} me={me} />;
              }}
            />

            <ProtectedRoute
              member={member}
              path="/admin/groups/add"
              component={GroupAdd}
            />
            <ProtectedRoute
              path="/admin/groups/:id/edit"
              component={GroupEdit}
              member={member}
            />

            <ProtectedRoute
              path="/admin/crews/times/:id"
              component={ProgramTimes}
              member={member}
            />
            <ProtectedRoute
              path="/admin/crews/stats/:id"
              component={ProgramStats}
              member={member}
            />
            <ProtectedRoute
              path="/admin/crews/stats"
              component={PlatformStats}
              member={member}
            />
            <ProtectedRoute
              path="/admin/crews/:id/edit"
              component={CrewsEdit}
              member={member}
            />
            <ProtectedRoute
              member={member}
              path="/admin/crews/:id"
              component={CrewsSingle}
            />
            <ProtectedRoute
              member={member}
              path="/admin/crews"
              component={Crews}
            />

            <ProtectedRoute
              member={member}
              path="/admin/events"
              component={EventsAdmin}
            />

            <ProtectedRoute
              path="/admin/lessons/:id/edit"
              component={LessonsEdit}
              member={member}
            />
            <ProtectedRoute
              path="/admin/lessons/:id"
              component={LessonsSingle}
              member={member}
            />

            <ProtectedRoute
              path="/admin/activities/:id/edit"
              component={ActivitiesEdit}
              member={member}
            />
            <ProtectedRoute
              path="/admin/activities/:id"
              component={ActivitiesSingle}
              member={member}
            />

            <ProtectedRoute
              path="/admin/program-management"
              component={Programs}
              member={member}
            />

            <ProtectedRoute
              path="/admin/programs/:id/edit"
              component={ProgramEdit}
              member={member}
            />
            <ProtectedRoute
              path="/admin/programs/:id"
              component={ProgramsSingle}
              member={member}
            />

            <ProtectedRoute
              path="/admin/companies"
              component={AdminCompanies}
              member={member}
            />

            <ProtectedRoute
              path="/admin/interests"
              component={AdminInterests}
              member={member}
            />

            <ProtectedRoute
              path="/admin/lessonTemplates/:id/edit"
              component={LessonTemplatesEdit}
              member={member}
            />
            <ProtectedRoute
              path="/admin/lessonTemplates/:id"
              component={LessonTemplatesSingle}
              member={member}
            />

            <Route path="/strengths/invite/:id" component={StrengthInvite} />

            <ProtectedRoute
              path="/strengths/invites"
              component={StrengthInvites}
              member={member}
            />

            <ProtectedRoute
              member={member}
              path="/strengths/sort"
              component={StrengthSort}
            />

            <ProtectedRoute
              path="/strengths/overview"
              component={StrengthOverview}
              member={member}
            />
            <ProtectedRoute
              member={member}
              path="/strengths/five"
              component={StrengthsFive}
            />

            <ProtectedRoute
              member={member}
              path="/strengths"
              component={Strengths}
            />

            <ProtectedRoute
              member={member}
              path="/coaching/program/:id"
              component={CoachingProgram}
            />

            <ProtectedRoute
              member={member}
              path="/coaching"
              component={CoachingDashboard}
            />

            <Route path="/visions/vision/:id" component={PublicVision} />

            <ProtectedRoute
              member={member}
              path="/visions/dashboard"
              component={VisionsDashboard}
            />

            <ProtectedRoute
              member={member}
              path="/visions/cover/edit/:id"
              component={CoverPageEdit}
            />

            <ProtectedRoute
              member={member}
              path="/visions/cover"
              component={CoverPage}
            />

            <ProtectedRoute
              member={member}
              path="/visions/enter"
              component={EnterPages}
            />

            <ProtectedRoute
              member={member}
              path="/visions"
              component={Visions}
            />

            <ProtectedRoute
              member={member}
              path="/board-of-advisors/dashboard"
              component={CareerCollaboratorsDashboard}
            />

            <ProtectedRoute
              member={member}
              path="/board-of-advisors/review"
              component={CareerCollaboratorsReview}
            />

            <ProtectedRoute
              member={member}
              path="/board-of-advisors/enter"
              component={CareerCollaboratorsEnter}
            />

            <ProtectedRoute
              member={member}
              path="/board-of-advisors"
              component={CareerCollaborators}
            />

            <Route
              member={member}
              path="/mindmap/collab/:id"
              component={CollabDiagram}
            />

            <ProtectedRoute
              member={member}
              path="/mindmap/dashboard"
              component={MindmapDashboard}
            />

            <ProtectedRoute
              member={member}
              path="/mindmap/diagram"
              component={CreateDiagram}
            />

            <ProtectedRoute
              member={member}
              path="/mindmap"
              component={Mindmap}
            />

            <ProtectedRoute
              member={member}
              path="/role-models/dashboard"
              component={RoleModelsDashboard}
            />

            <ProtectedRoute
              member={member}
              path="/role-models/values"
              component={() => {
                return <RoleModelValuesCreation me={me} />;
              }}
            />

            <ProtectedRoute
              member={member}
              path="/role-models"
              component={RoleModels}
            />

            <ProtectedRoute
              member={member}
              path="/board-of-advisors"
              component={CareerCollaborators}
            />

            <ProtectedRoute
              member={member}
              path="/tying/dashboard"
              component={TopicsDashboard}
            />

            <ProtectedRoute
              member={member}
              path="/tying/topics"
              component={Topics}
            />

            <ProtectedRoute
              member={member}
              path="/tying"
              component={TyingItAllTogether}
            />

            <Route
              member={member}
              path="/values/feedback/:id"
              component={FeedbackInvite}
            />
            <Route
              member={member}
              path="/values/invite/:id"
              component={ValueInvite}
            />
            <ProtectedRoute
              member={member}
              path="/values/creation"
              component={ValuesCreation}
            />
            <ProtectedRoute
              member={member}
              path="/values/dashboard"
              component={ValuesDashboard}
            />

            <ProtectedRoute member={member} path="/values" component={Values} />

            <Route member={member} path="/login" component={Signup} />
            <Route member={member} path="/signup" component={Login} />

            <ProtectedRoute
              member={member}
              path="/learn/activities"
              component={LearnActivities}
            />
            <ProtectedRoute
              member={member}
              path="/learn/paths/:id"
              component={LearnPath}
            />
            <ProtectedRoute
              member={member}
              path="/learn/paths"
              component={LearnPaths}
            />
            <ProtectedRoute member={member} path="/learn" component={Learn} />

            <ProtectedRoute
              member={member}
              me={me}
              path="/program"
              component={Program}
            />
            <ProtectedRoute
              member={member}
              path="/admin/groups"
              component={Admin}
            />
            <ProtectedRoute
              path="/admin/coupons/create"
              component={AdminCouponsCreate}
              member={member}
            />
            <ProtectedRoute
              member={member}
              path="/admin/coupons"
              component={AdminCoupons}
            />
            <ProtectedRoute
              path="/admin/learningActivities/create"
              component={LearningActivitiesCreate}
              member={member}
            />
            <ProtectedRoute
              path="/admin/learningActivities/edit/:id"
              component={LearningActivitiesEdit}
              member={member}
            />
            <ProtectedRoute
              member={member}
              path="/admin/learningActivities"
              component={AdminLearningActivities}
            />

            <ProtectedRoute
              member={member}
              path="/admin/memberships"
              component={AdminMemberships}
            />

            <ProtectedRoute
              member={member}
              path="/admin"
              component={Programs}
            />

            <Route path="/interest-form" component={InterestForm} />
            <Route path="/o-interest-form" component={OpenInterestForm} />

            <Route
              path="/logout"
              component={() => {
                localStorage.removeItem("me");
                logout({
                  returnTo: window.location.origin,
                });
              }}
            />
            <Route path="/privacy" component={PrivacyPolicy} />
            <Route path="*" component={NotFound} />
          </Switch>
        </div>
      </div>
    </div>
  );
};
