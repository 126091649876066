import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { useEnv } from "../../../context/env.context";
import axios from "axios";
import { NavLink, useHistory } from "react-router-dom";
import "../../../styles/pages/Admin/Crews/edit.scss";
import "react-datepicker/dist/react-datepicker.css";
import { isAdmin } from "../../../hooks/isAdmin";
import { SessionTimeEditor } from "../../../components/admin/SessionTimeEditor";
import ReactDatePicker from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowCircleUp,
  faCheck,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  Select,
} from "@mui/material";

export const ProgramTimes = (props) => {
  const { getAccessTokenSilently } = useAuth0();
  const { apiServerUrl } = useEnv();
  const [saved, setSaved] = useState(false);
  const [timeFrame, setTimeFrame] = useState(0);
  const [meetingUrl, setMeetingUrl] = useState("");
  const [firstSession, setFirstSession] = useState();
  const [sessionLength, setSessionLength] = useState(0);
  const [showTransfer, setShowTransfer] = useState(false);
  const [transferId, setTransferId] = useState(null);
  const [activeTransferUser, setActiveTransferUser] = useState(null);
  const [showDelete, setShowDelete] = useState(false);
  const [sessions, setSessions] = useState([]);
  const [interests, setInterests] = useState([]);
  const [crews, setCrews] = useState([]);
  const [crew, setCrew] = useState({
    name: "",
    description: "",
  });
  const history = useHistory();
  const [coaches, setCoaches] = useState([]);
  const [me, setMe] = useState({
    id: null,
  });
  let id = props.match.params.id;

  useEffect(() => {
    getMe();
    getCrew();
    getCrews();
    getInterests();
  }, []);

  const getInterests = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/crews/interests/${id}`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };

    config.headers = {
      Authorization: `Bearer ${token}`,
    };

    const response = await axios(config);

    setInterests(response.data);
  };

  const deleteFromInterest = async (interestId) => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/interest/remove/${interestId}`,
      method: "DELETE",
      headers: {
        "content-type": "application/json",
      },
    };

    config.headers = {
      Authorization: `Bearer ${token}`,
    };

    await axios(config);

    getInterests();
  };

  const getCrews = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await axios.get(`${apiServerUrl}/api/crews`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // Remove this crew
      const filteredCrews = response.data.filter((crew) => {
        return crew.id !== Number(id);
      });

      setCrews(filteredCrews);
    } catch (error) {
      console.error(error);
    }
  };

  const deleteFromCrew = async (userId, status) => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/crews/${crew.id}/users/${userId}?status=${status}`,
      method: "DELETE",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);

    getCrew();
  };

  const transferCrews = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/crews/${crew.id}/users/${activeTransferUser}`,
      method: "POST",
      data: {
        newCrewId: transferId,
      },
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);

    getCrew();
  };

  const searchCoaches = async (search) => {
    const token = await getAccessTokenSilently();

    if (search.length < 3) {
      return setCoaches([]);
    }

    const config = {
      url: `${apiServerUrl}/api/users?search=${search}`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);

    setCoaches(response.data);
  };

  const getMe = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/users/me`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);

    // Admin Check
    if (!isAdmin(response.data)) {
      return history.push("/dashboard");
    }

    setMe(response.data);
  };

  const saveCrew = async () => {
    const token = await getAccessTokenSilently();

    const data = { ...crew, coachId: crew.coach.id };

    const config = {
      url: `${apiServerUrl}/api/crews/${crew.id}`,
      method: "PATCH",
      headers: {
        "content-type": "application/json",
      },
      data,
    };

    config.headers = {
      Authorization: `Bearer ${token}`,
    };

    const response = await axios(config);

    setSaved(true);

    setTimeout(() => {
      setSaved(false);
    }, 5000);
  };

  const changeActiveSession = async (sessionId) => {
    const token = await getAccessTokenSilently();

    const data = { sessionId };

    const config = {
      url: `${apiServerUrl}/api/crews/${crew.id}/activeSession`,
      method: "PATCH",
      headers: {
        "content-type": "application/json",
      },
      data,
    };

    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);

    getCrew();
  };

  const bulkChange = async () => {
    const token = await getAccessTokenSilently();

    const data = { timeFrame, firstSession, sessionLength, meetingUrl };

    const config = {
      url: `${apiServerUrl}/api/crews/${crew.id}/bulkChange`,
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      data,
    };

    config.headers = {
      Authorization: `Bearer ${token}`,
    };

    const response = await axios(config);

    window.location.reload();
  };

  const getCrew = async () => {
    const token = await getAccessTokenSilently();

    const config = {
      url: `${apiServerUrl}/api/crews/${id}`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);

    const data = { ...response.data };

    const sessions = data.program.sessions;

    const sortedSessions = sessions.sort((a, b) => {
      return a.order - b.order;
    });

    const UserSessions = sortedSessions.map((session, index) => {
      const newSession = { ...session };

      const usersSectioned = data.users.map((user) => {
        const newUser = { ...user };
        newUser.attended = false;
        const found = session.attendances.find((a) => {
          return a.userId === user.id;
        });
        if (found?.attended) newUser.attended = true;
        return newUser;
      });

      newSession.users = usersSectioned;
      return newSession;
    });
    setSessions(UserSessions);
    setCrew(data);
  };

  if (!crew.id) {
    return <div>Loading...</div>;
  }

  return (
    <div className="ProgramEdit">
      <div className="breadcrumbs">
        <div className="breadcrumb">
          <NavLink to={"/admin/crews"}>Crews</NavLink> /{" "}
        </div>
        <div className="breadcrumb">
          <div className="breadcrumb">
            <NavLink to={`/admin/crews/${crew.id}`}>{crew.name}</NavLink>{" "}
          </div>
        </div>
      </div>
      <div className="container white-background">
        <h2 className="heading1 highlight blue">Edit {crew.name}</h2>

        <div className="bulkContainer">
          <h3>Bulk Editor</h3>
          <div className="container">
            <div className="form-input">
              <label>First Session</label>
              <ReactDatePicker
                dateFormat="Pp"
                selected={firstSession}
                showTimeSelect
                onChange={(e) => {
                  setFirstSession(e);
                }}
              />
            </div>
            <div className="form-input">
              <label>Time Frame (days)</label>
              <input
                onChange={(e) => {
                  setTimeFrame(e.target.value);
                }}
                type="number"
                value={crew.timeFrame}
              />
            </div>
            <div className="form-input">
              <label>Session Length (hours)</label>
              <input
                onChange={(e) => {
                  setSessionLength(e.target.value);
                }}
                type="number"
                value={crew.timeFrame}
              />
            </div>
            <div className="form-input">
              <label>Meeting Url</label>
              <input
                onChange={(e) => {
                  setMeetingUrl(e.target.value);
                }}
                type="text"
                value={meetingUrl}
              />
            </div>
          </div>
          <button
            disabled={!sessionLength || !timeFrame || !meetingUrl}
            onClick={bulkChange}
            className="btn primary"
          >
            Save
          </button>
        </div>

        <div className="coachContainer">
          <h3>Coach</h3>
          <div className="coach">
            {crew.coach?.first_name && (
              <div className="coach-info">
                <div className="coach-image">
                  <img src={crew.coach?.avatar} alt={crew.coach?.first_name} />
                </div>
                <div className="coaching-more-info">
                  <div className="coach-name">
                    {crew.coach?.first_name} {crew.coach?.last_name}
                  </div>
                  <div className="coach-email">{crew.coach?.email}</div>
                </div>
              </div>
            )}
            <div className="coach-search">
              <input
                onChange={(e) => {
                  searchCoaches(e.target.value);
                }}
                type="text"
                placeholder="Search for a coach"
              />
              {coaches.length > 0 && (
                <div className="coaching-staff">
                  {coaches.map((coach) => (
                    <div className="coach">
                      <div
                        onClick={() => {
                          setCrew({ ...crew, coach: coach });
                        }}
                        className="coach-info"
                      >
                        <div className="coach-name">
                          {coach.first_name} {coach.last_name}
                        </div>
                        <div className="coach-email">{coach.email}</div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
            {crew.coach && (
              <button
                onClick={() => {
                  saveCrew();
                }}
                className="btn primary"
              >
                {saved ? "Saved" : "Save"}
              </button>
            )}
          </div>

          <h3>Welcome Pack</h3>
          <div className="welcomePack">
            <input
              onChange={(e) => {
                setCrew({ ...crew, welcomePackUrl: e.target.value });
              }}
              type="text"
              value={crew.welcomePackUrl}
              placeholder="Welcome Pack Url"
            />
            <button
              onClick={() => {
                saveCrew();
              }}
              className="btn primary"
            >
              {saved ? "Saved" : "Save"}
            </button>
          </div>
        </div>
        <div className="row">
          {sessions.map((session, index) => (
            <SessionTimeEditor
              session={session}
              index={index}
              key={session.id}
              changeActiveSession={changeActiveSession}
              program={crew}
              setCrew={setCrew}
            />
          ))}
        </div>
      </div>

      <div className="container white-background users">
        <h2 className="heading1 highlight blue">Crew Members</h2>
        {crew.users.map((user) => (
          <div className="user">
            <div className="transfer">
              <button
                onClick={() => {
                  setShowTransfer(true);
                  setActiveTransferUser(user.id);
                }}
              >
                <FontAwesomeIcon icon={faArrowCircleUp} />
              </button>
            </div>
            <div className="delete">
              <button
                onClick={() => {
                  setShowDelete(true);
                  setActiveTransferUser(user.id);
                }}
              >
                <FontAwesomeIcon icon={faTrash} />
              </button>
            </div>
            {!user.avatar && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
              >
                <path
                  d="M16.5716 7.25686C16.5716 9.52912 14.7296 11.3712 12.4573 11.3712C10.1851 11.3712 8.34304 9.52912 8.34304 7.25686C8.34304 4.98461 10.1851 3.14258 12.4573 3.14258C14.7296 3.14258 16.5716 4.98461 16.5716 7.25686Z"
                  stroke="#0B455F"
                  strokeWidth="2.05714"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M12.4573 14.4569C8.48087 14.4569 5.25732 17.6804 5.25732 21.6569H19.6573C19.6573 17.6804 16.4338 14.4569 12.4573 14.4569Z"
                  stroke="#0B455F"
                  strokeWidth="2.05714"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            )}
            {user.avatar && <img src={user.avatar} alt={user.first_name} />}
            <h4>
              {user.first_name} {user.last_name}
            </h4>
            <h4>{user.email}</h4>
          </div>
        ))}
      </div>
      <div className="container white-background users">
        <h2 className="heading1 highlight blue">Sign Ups</h2>
        {interests.map((interest) => (
          <div className="user">
            <div className="delete">
              <button
                onClick={() => {
                  deleteFromInterest(interest.id);
                }}
              >
                <FontAwesomeIcon icon={faTrash} />
              </button>
            </div>
            <img
              src={`https://placehold.co/150x150?text=${interest.name}`}
              alt={interest.name}
            />
            <h4>{interest.name}</h4>
            <h4>{interest.email}</h4>
            {interest.optOutEmailSent && (
              <p>
                <FontAwesomeIcon icon={faCheck} /> Opt Out Email Sent
              </p>
            )}
            {interest.type === "intern" && interest.signUpEmailSent && (
              <p>
                <FontAwesomeIcon icon={faCheck} /> Signup Email Sent
              </p>
            )}
            {interest.reminderEmailSent && (
              <p>
                <FontAwesomeIcon icon={faCheck} /> Reminder Email Sent
              </p>
            )}
          </div>
        ))}
      </div>
      <React.Fragment>
        <Dialog
          open={showTransfer}
          onClose={() => {
            setShowTransfer(false);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Transfer User</DialogTitle>
          <DialogContent className="transferModal">
            <DialogContentText id="alert-dialog-description">
              Please select what crew you would like to transfer this user to.
            </DialogContentText>

            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={transferId}
              label="Age"
              onChange={(e) => {
                setTransferId(e.target.value);
              }}
            >
              {crews.map((crew) => {
                return <MenuItem value={crew.id}>{crew.name}</MenuItem>;
              })}
            </Select>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setShowTransfer(false);
              }}
            >
              Disagree
            </Button>
            <Button
              onClick={async () => {
                transferCrews();
                setShowTransfer(false);
              }}
              autoFocus
            >
              Agree
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>

      <React.Fragment>
        <Dialog
          open={showDelete}
          onClose={() => {
            setShowDelete(false);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Delete From Crew</DialogTitle>
          <DialogContent className="transferModal">
            <DialogContentText id="alert-dialog-description">
              Would you like to remove them back to signups or delete them?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                deleteFromCrew(activeTransferUser, false);
                setShowTransfer(false);
              }}
            >
              Back To Sign Ups
            </Button>
            <Button
              onClick={async () => {
                deleteFromCrew(activeTransferUser, true);
                setShowDelete(false);
              }}
              autoFocus
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    </div>
  );
};
