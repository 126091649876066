import React, { useEffect, useState } from "react";
import "./styles/ChoosePrompts.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronUp,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { useAuth0 } from "@auth0/auth0-react";
import { useEnv } from "../../../context/env.context";
import axios from "axios";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import modalLightbulb from "../images/modalLightbulb.png";
import { Analytics } from "../../../hooks/analytics";

export const ChoosePrompts = ({ setStep, me }) => {
  const [choosenPrompts, setChoosenPrompts] = useState({
    growth: null,
    relationships: null,
    impact: null,
  });
  const [showIntro, setShowIntro] = useState(true);

  const history = useHistory();

  const { getAccessTokenSilently } = useAuth0();
  const { apiServerUrl } = useEnv();

  const [expandedBox, setExpandedBox] = useState(null);

  const growth = [
    "How have you used your strengths?",
    "How have you lived your values?",
    "How have you grown?",
    "What have you learned?",
    "How do you feel?",
  ];

  const relationships = [
    "What do you colleagues say about working with you?",
    "What does your manager say about working with you?",
    "What do your customers say about working with you?",
    "How have you contributed to the culture of your team?",
    "How have you contributed to the culture of your company?",
  ];

  const impact = [
    "What impact have you made?",
    "What challenges have you overcome?",
    "What results have you achieved?",
  ];

  useEffect(() => {}, []);

  const finish = async () => {
    const token = await getAccessTokenSilently();

    const pages = [
      {
        prompt: choosenPrompts.growth,
        category: "growth",
        order: 1,
      },
      {
        prompt: choosenPrompts.relationships,
        category: "relationships",
        order: 2,
      },
      {
        prompt: choosenPrompts.impact,
        category: "impact",
        order: 3,
      },
    ];

    const data = {
      title: "One Year Vision",
      subtitle: "Looking back on the last year, I was...",
      pages,
    };

    const config = {
      url: `${apiServerUrl}/api/visions`,
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      data,
    };

    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios(config);

    Analytics("Visions - Saved Prompts");
    Analytics("Visions - Started");
    history.push("/visions/enter");
  };

  return (
    <div className="choosePrompts">
      <div className="sidebar">
        <div className="title">
          <FontAwesomeIcon
            icon={faTimes}
            onClick={() => {
              setStep(0);
            }}
          />

          <h3>One-Year Vision Planning</h3>
        </div>

        <div className="inner">
          <div className="subtitle">
            <h4>Pick your prompts</h4>
            <p>Choose one from each category</p>
          </div>

          <div className="prompts">
            <div
              className={`prompt growth ${
                ((choosenPrompts.growth === null &&
                choosenPrompts.impact === null &&
                expandedBox !== "growth" &&
                choosenPrompts.relationships === null) || expandedBox === "growth")
                  ? "expanded"
                  : ""
              }`}
            >
              <div className="title">
                <h3>Personal Growth</h3>
                {expandedBox === "growth" ? (
                  <span
                    onClick={() => {
                      setExpandedBox(null);
                    }}
                  >
                    Show Less <FontAwesomeIcon icon={faChevronDown} />
                  </span>
                ) : (
                  <span
                    onClick={() => {
                      setExpandedBox("growth");
                    }}
                  >
                    Show More <FontAwesomeIcon icon={faChevronUp} />
                  </span>
                )}
                <p>Prompts about yourself and who you hope to become</p>
              </div>

              <div className="expanding">
                {growth.map((prompt) => {
                  return (
                    <div
                      onClick={() => {
                        const newChoosenPrompts = { ...choosenPrompts };
                        newChoosenPrompts.growth = prompt;
                        setChoosenPrompts(newChoosenPrompts);
                      }}
                      className={`box blue ${
                        choosenPrompts.growth === prompt ? "selected" : ""
                      }`}
                    >
                      <p>{prompt}</p>
                    </div>
                  );
                })}
              </div>
            </div>

            <div
              className={`prompt relationships ${
                ((choosenPrompts.growth !== null &&
                choosenPrompts.impact === null &&
                expandedBox !== "relationships" &&
                choosenPrompts.relationships === null) || expandedBox === "relationships")
                  ? "expanded"
                  : ""
              }`}
            >
              <div className="title">
                <h3>Relationships</h3>
                {expandedBox === "relationships" ? (
                  <span
                    onClick={() => {
                      setExpandedBox(null);
                    }}
                  >
                    Show Less <FontAwesomeIcon icon={faChevronDown} />
                  </span>
                ) : (
                  <span
                    onClick={() => {
                      setExpandedBox("relationships");
                    }}
                  >
                    Show More <FontAwesomeIcon icon={faChevronUp} />
                  </span>
                )}
                <p>Prompts about how you interact with those around you</p>
              </div>

              <div className="expanding">
                {relationships.map((prompt) => {
                  return (
                    <div
                      onClick={() => {
                        const newChoosenPrompts = { ...choosenPrompts };
                        newChoosenPrompts.relationships = prompt;
                        setChoosenPrompts(newChoosenPrompts);
                      }}
                      className={`box yellow ${
                        choosenPrompts.relationships === prompt
                          ? "selected"
                          : ""
                      }`}
                    >
                      <p>{prompt}</p>
                    </div>
                  );
                })}
              </div>
            </div>

            <div
              className={`prompt impact ${
                ((choosenPrompts.growth !== null &&
                choosenPrompts.impact === null &&
                choosenPrompts.relationships !== null) || expandedBox === "impact")
                  ? "expanded"
                  : ""
              }`}
            >
              <div className="title">
                <h3>Impact</h3>
                {expandedBox === "impact" ? (
                  <span
                    onClick={() => {
                      setExpandedBox(null);
                    }}
                  >
                    Show Less <FontAwesomeIcon icon={faChevronDown} />
                  </span>
                ) : (
                  <span
                    onClick={() => {
                      setExpandedBox("impact");
                    }}
                  >
                    Show More <FontAwesomeIcon icon={faChevronUp} />
                  </span>
                )}
                <p>Prompts about what results you achieve</p>
              </div>

              <div className="expanding">
                {impact.map((prompt) => {
                  return (
                    <div
                      onClick={() => {
                        const newChoosenPrompts = { ...choosenPrompts };
                        newChoosenPrompts.impact = prompt;
                        setChoosenPrompts(newChoosenPrompts);
                      }}
                      className={`box red ${
                        choosenPrompts.impact === prompt ? "selected" : ""
                      }`}
                    >
                      <p>{prompt}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>

        <div className="bottom">
          <button className="btn blank">Back</button>
          <button
            disabled={
              !choosenPrompts.growth ||
              !choosenPrompts.relationships ||
              !choosenPrompts.impact
            }
            onClick={() => {
              finish();
            }}
            className="btn secondary"
          >
            Continue
          </button>
        </div>
      </div>

      <div className="right">
        <div className="slides">
          {choosenPrompts.growth && (
            <div
              onClick={() => {
                setExpandedBox("growth");
              }}
              className="box"
            >
              <span>PERSONAL GROWTH</span>
              <h5>Looking back on the last year...</h5>
              <p>{choosenPrompts.growth}</p>
            </div>
          )}
          {!choosenPrompts.growth && (
            <div className="box blank">
              <span>PERSONAL GROWTH</span>
              <h5>Looking back on the last year...</h5>
              <p>Choose A Prompt</p>
            </div>
          )}
          {choosenPrompts.relationships && (
            <div
              onClick={() => {
                setExpandedBox("relationships");
              }}
              className="box yellow"
            >
              <span>RELATIONSHIPS</span>
              <h5>Looking back on the last year...</h5>
              <p>{choosenPrompts.relationships}</p>
            </div>
          )}
          {!choosenPrompts.relationships && (
            <div className="box blank">
              <span>RELATIONSHIPS</span>
              <h5>Looking back on the last year...</h5>
              <p>Choose A Prompt</p>
            </div>
          )}
          {choosenPrompts.impact && (
            <div
              onClick={() => {
                setExpandedBox("impact");
              }}
              className="box red"
            >
              <span>IMPACT</span>
              <h5>Looking back on the last year...</h5>
              <p>{choosenPrompts.impact}</p>
            </div>
          )}
          {!choosenPrompts.impact && (
            <div className="box blank">
              <span>IMPACT</span>
              <h5>Looking back on the last year...</h5>
              <p>Choose A Prompt</p>
            </div>
          )}
        </div>
      </div>
      {showIntro && (
        <div className="modal">
          <div className="modal-content">
            <div className="left">
              <img src={modalLightbulb} alt="" />
            </div>
            <div className="right">
              <h2>Here's what to expect</h2>
              <p>
                In this activity, you'll come up with a one-year vision for your
                time here.
              </p>
              <h5>Here's what to do before your next session:</h5>
              <div className="icons">
                <div className="item">
                  <span>1</span>
                  <div className="content">
                    <h4>First, choose three prompts to answer</h4>
                    <p>Pick one from each category.</p>
                  </div>
                </div>
                <div className="item">
                  <span>2</span>
                  <div className="content">
                    <h4>Then, answer your prompts</h4>
                    <p>Be detailed and descriptive. Make it real!</p>
                  </div>
                </div>
                <div className="item">
                  <span>3</span>
                  <div className="content">
                    <h4>
                      Lastly, bottom-line your responses to a few key words
                    </h4>
                  </div>
                </div>
              </div>
              <p>
                You'll create your cover pages with the help of your coach and
                crew during the session.
              </p>

              <div className="actions">
                <button
                  className="btn secondary"
                  onClick={() => {
                    setShowIntro(false);
                  }}
                >
                  Ready to Begin
                </button>
              </div>
            </div>
          </div>
          <div
            className="skrim"
            onClick={() => {
              setShowIntro(false);
            }}
          ></div>
        </div>
      )}
    </div>
  );
};
